import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Tag } from '@peakon/bedrock/react/tag';
import { Paragraph } from '@peakon/bedrock/react/typography';
import {
  View,
  Spinner,
  Select,
  Button,
  InputField,
  Label,
} from '@peakon/components';

import * as AccessActions from '../../actions/AccessActions';
import { ACCESS_ROLE_CONSTANTS } from '../../constants/AccessRoleConstants';
import { REGION_HUMAN_MAP } from '../../constants/RegionConstants';

import styles from './styles.css';

const ACCESS_ROLE_SELECT_OPTIONS = ACCESS_ROLE_CONSTANTS.map((r) => ({
  value: r,
  label: r,
}));

const REGION_HUMAN_MAP_OPTIONS = Object.keys(REGION_HUMAN_MAP).map((region) => {
  return {
    value: region,
    label: REGION_HUMAN_MAP[region],
  };
});

class BackofficeAccess extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      companyId: '',
      email: '',
      role: null,
      region: null,
    };
  }

  componentDidMount() {
    const {
      accessActions: { getAccounts },
    } = this.props;

    getAccounts();
  }

  render() {
    const { isLoading, accounts } = this.props;
    const { companyId, email, role, region } = this.state;

    return (
      <View className={styles.container}>
        {isLoading && <Spinner />}
        <View className={styles.grantAccess}>
          <form action="" onSubmit={this.handleGrantAccess}>
            <View className={styles.field}>
              <InputField
                label="Grant access"
                inputType="email"
                onChange={(value) => this.setState({ email: value })}
                value={email}
                placeholder="email@peakon.com"
              />
            </View>
            <View className={styles.field}>
              <Label>Role</Label>
              <Select
                onChange={(value) => this.setState({ role: value })}
                data-testid="value"
                value={role}
                options={ACCESS_ROLE_SELECT_OPTIONS}
                isClearable={false}
                placeholder="Select role.."
              />
            </View>
            {role?.value === 'partner_consultant' && (
              <View className={styles.field}>
                <InputField
                  label="Company Id"
                  inputType="text"
                  onChange={(value) => this.setState({ companyId: value })}
                  value={companyId}
                  placeholder="12345"
                />
              </View>
            )}
            <View className={styles.field}>
              <Label>Region</Label>
              <Select
                onChange={(value) => this.setState({ region: value })}
                data-testid="region"
                value={region}
                options={REGION_HUMAN_MAP_OPTIONS}
                isClearable={false}
                placeholder="Select region.."
              />
            </View>
            <View className={styles.submit}>
              <Button disabled={isLoading || !this.hasValidNewAccount()}>
                Create
              </Button>
            </View>
          </form>
        </View>
        {!isLoading && accounts.length > 0 && (
          <table>
            <thead>
              <tr>
                <td className={styles.companyId}>Company Id</td>
                <td className={styles.email}>Email</td>
                <td>Role</td>
                <td>Region</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => {
                const roleValue = ACCESS_ROLE_SELECT_OPTIONS.find(
                  (option) => option.value === account.role,
                );
                const regionValue = REGION_HUMAN_MAP_OPTIONS.find(
                  (option) => option.value === account.region,
                );

                return (
                  <tr key={account.id}>
                    <td className={styles.companyId}>
                      {account.companyId ? (
                        <Paragraph bold>{account.companyId}</Paragraph>
                      ) : (
                        <Tag variant="default" label="unassigned" />
                      )}
                    </td>
                    <td className={styles.email} title={account.email}>
                      {account.email}
                    </td>
                    <td>
                      <Select
                        onChange={(selected) =>
                          this.handleAccountChange('role', selected, account)
                        }
                        value={roleValue}
                        options={ACCESS_ROLE_SELECT_OPTIONS}
                        isClearable={false}
                      />
                    </td>
                    <td>
                      <Select
                        onChange={(selected) =>
                          this.handleAccountChange('region', selected, account)
                        }
                        value={regionValue}
                        options={REGION_HUMAN_MAP_OPTIONS}
                        isClearable={false}
                      />
                    </td>
                    <td>
                      <Button
                        size="small"
                        type="danger"
                        onClick={() =>
                          this.handleRevokeBackOfficeAccess(account)
                        }
                      >
                        Revoke Access
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </View>
    );
  }

  handleAccountChange = (type, selected, account) => {
    const {
      accessActions: { udpate },
    } = this.props;

    const body = {
      role: account.role,
      region: account.region,
      [type]: selected.value,
    };

    udpate(account.id, body);
  };

  handleRevokeBackOfficeAccess = (account) => {
    const {
      accessActions: { removeAccount },
    } = this.props;
    const { id, email } = account;

    const message = `Are you sure you want to revoke backoffice access for ${email}?`;

    if (confirm(message)) {
      removeAccount(id);
    }
  };

  handleGrantAccess = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    const {
      accessActions: { addAccount },
    } = this.props;
    const { companyId, email, role, region } = this.state;

    addAccount(email, {
      companyId,
      role: role.value,
      region: region.value,
    });
  };

  hasValidNewAccount() {
    const { companyId, email, role, region } = this.state;

    return (
      (role !== 'partner_consultant' || companyId) && email && role && region
    );
  }
}

BackofficeAccess.propTypes = {
  accessActions: PropTypes.object,
  isLoading: PropTypes.bool,
  accounts: PropTypes.array,
};

BackofficeAccess.defaultProps = {
  isLoading: true,
  accounts: [],
};

const mapStateToProps = (state) => {
  const {
    access: { isLoading, accounts },
  } = state;

  return {
    isLoading,
    accounts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  accessActions: bindActionCreators(AccessActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeAccess);
