import React from 'react';

import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { InputField as BRInputField } from '@peakon/bedrock/react/form';

export const InputField = ({ name, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: { required: true },
  });

  return (
    <BRInputField
      ref={field.ref}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      status={error && 'error'}
      feedbackMessage={error?.message}
      {...props}
    />
  );
};

InputField.propTypes = {
  name: PropTypes.string,
};
