import React, { Component, Fragment } from 'react';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import {
  FaWindows,
  FaApple,
  FaLinux,
  FaChrome,
  FaFirefox,
  FaInternetExplorer,
  FaEdge,
} from 'react-icons/fa';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { bindActionCreators } from 'redux';

import {
  Spinner,
  Button,
  Modal,
  Select,
  DatePicker,
  Checkbox,
  InputField,
} from '@peakon/components';

import ExportAudits from './AuditExport';
import PeakonLogo from './peakon.svg';
import * as AuditActions from '../../actions/AuditActions';
import { getAuditChanges, getSafeValue } from '../../utils/AuditHelper';
import { parseInteger } from '../../utils/parseInteger';
import CompanyInfo from '../CompanyInfo';

import styles from './styles.css';

function getActor(audit) {
  const {
    relationships: { account, appIntegration },
  } = audit;

  if (account) {
    if (Object.prototype.hasOwnProperty.call(account, 'attributes')) {
      return {
        type: 'account',
        account,
      };
    }
  }
  if (appIntegration) {
    if (Object.prototype.hasOwnProperty.call(appIntegration, 'attributes')) {
      return {
        type: 'appIntegration',
        appIntegration,
      };
    }
  }
}

function formatDate(date) {
  return moment(date).utc().format('MMMM Do YYYY, HH:mm:ss (z)');
}

function getEventsByObjectType(objectTypes, requestedObjectType) {
  function getEvents(objectType) {
    return Object.keys(objectTypes[objectType]).map((event) => {
      return {
        value: `${objectType}.${event}`,
        objectType,
        event,
        label: objectTypes[objectType][event],
      };
    });
  }

  if (requestedObjectType) {
    return getEvents(requestedObjectType);
  }

  return flatten(
    Object.keys(objectTypes).map((objectType) => {
      return getEvents(objectType);
    }),
  );
}

class Audits extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      isModalOpen: false,
      audit: null,
      showData: false,
      hasInputError: false,
    };
  }

  componentDidMount() {
    this.search();

    const { auditActions } = this.props;
    auditActions.getAuditObjectTypes();
  }

  componentWillUnmount() {
    const {
      auditActions: { reset },
    } = this.props;

    reset();
  }

  render() {
    const { isLoading, audits, employee, objectTypes } = this.props;
    const { objectType, event, from, to, hideSystemEvents, objectId } =
      this.state;

    const hasAnyFilter = Boolean(
      objectType || event || from || to || hideSystemEvents || objectId,
    );
    const isEmployeeView = Boolean(employee);

    const objectTypeOptions = Object.keys(objectTypes || {}).map((value) => ({
      value,
      label: value,
    }));

    const eventOptions = getEventsByObjectType(objectTypes || {}, objectType);

    return (
      <div className={styles.container}>
        <h2>Audits</h2>
        {isLoading && !audits && <Spinner />}

        {objectTypes && (
          <div className={styles.filtering}>
            <div className={styles.filter}>
              <DatePicker
                month={null}
                value={from}
                renderCalendar
                onDateChange={(value) => this.handleDateChange(value, 'from')}
                label="Date From"
              />
            </div>
            <div className={styles.filter}>
              <DatePicker
                month={null}
                value={to}
                renderCalendar
                onDateChange={(value) => this.handleDateChange(value, 'to')}
                label="Date To"
                disabled={!from}
              />
            </div>
            {!isEmployeeView && (
              <div className={styles.filter}>
                <InputField
                  inputType="text"
                  placeholder="ObjectId"
                  label="Object Id"
                  value={objectId}
                  onChange={(value) => this.handleObjectIdChange(value.trim())}
                  validation={this.state.hasInputError ? 'error' : 'success'}
                />
              </div>
            )}
            <div className={styles.filter}>
              <label htmlFor="action-type">Action Type</label>
              <Select
                onChange={this.handleObjectTypeChange}
                value={
                  objectTypeOptions.find((ot) => ot.value === objectType) ||
                  null
                }
                options={objectTypeOptions}
                isClearable
                placeholder="Select Action Type"
                inputId="action-type"
              />
            </div>
            <div className={styles.filter}>
              <label htmlFor="action-type">Action Event</label>
              <Select
                onChange={this.handleEventChange}
                value={
                  event
                    ? eventOptions.find((e) => e.value === event.value)
                    : null
                }
                options={eventOptions}
                isClearable
                placeholder="Select Action Event"
                inputId="action-event"
              />
            </div>
            {!isEmployeeView && (
              <div className={styles.export}>
                <ExportAudits
                  objectType={this.state.objectType}
                  event={this.state.event ? this.state.event.event : undefined}
                  from={this.state.from}
                  to={this.state.to}
                  objectId={this.state.objectId}
                  companyId={this.props.companyId}
                  employee={this.props.employee}
                  hasInputError={this.state.hasInputError}
                />
              </div>
            )}
          </div>
        )}

        <div className={styles.actions}>
          <Checkbox
            checked={hideSystemEvents}
            onChange={this.handlehideSystemEventsChange}
          >
            Hide System Events
          </Checkbox>
          {hasAnyFilter && (
            <div className={styles.reset}>
              <Button onClick={this.resetFilter}>Reset filter</Button>
            </div>
          )}
        </div>

        {!isLoading && audits.length === 0 && (
          <div className={styles.empty}>
            <span>Could not find any Audits.</span>
          </div>
        )}

        {audits.length > 0 && (
          <Fragment>
            <table>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Actor</td>
                  {isEmployeeView && <td>Company</td>}
                  <td>Action</td>
                  <td>Changes</td>
                  <td>&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                {audits.map((audit) => {
                  const {
                    id,
                    attributes,
                    relationships: { company },
                  } = audit;

                  const actor = getActor(audit);
                  const changes =
                    attributes.data && getAuditChanges(attributes.data);

                  if (!actor && hideSystemEvents) {
                    return null;
                  }

                  return (
                    <tr key={id}>
                      <td title={attributes.createdAt}>
                        {formatDate(attributes.createdAt)}
                      </td>
                      <td>{this.renderActor(actor)}</td>
                      {isEmployeeView && <td>{this.renderCompany(company)}</td>}
                      <td className={styles.text}>
                        {attributes.text} (id: {attributes.objectId})
                      </td>
                      <td>
                        {changes && (
                          <ul>
                            {changes.map((change) => {
                              let { key, oldValue, newValue } = change;

                              oldValue = getSafeValue(oldValue);
                              newValue = getSafeValue(newValue);

                              return (
                                <li key={`${id}-${key}`}>
                                  <strong>{key}</strong> changed from{' '}
                                  <strong title={oldValue}>{oldValue}</strong>{' '}
                                  to{' '}
                                  <strong title={newValue}>{newValue}</strong>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </td>
                      <td>
                        <Button
                          size="small"
                          type="muted"
                          onClick={(e) => this.handleModalOpen(e, audit)}
                        >
                          Details
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {isLoading && <Spinner />}
            <InView
              onChange={(inView) => inView && this.handleContinuation(inView)}
            />
          </Fragment>
        )}
        <div>{this.renderModal()}</div>
      </div>
    );
  }

  renderCompany(company, size = 'small') {
    if (!company) {
      return null;
    }

    const { id, attributes } = company;

    const companyInfo = {
      id,
      ...attributes,
    };

    return (
      <Link to={`/companies/${id}/audits`} onClick={this.handleModalClose}>
        <CompanyInfo company={companyInfo} size={size} />
      </Link>
    );
  }

  renderActor(actor) {
    if (!actor) {
      return <span className={styles.system}>System</span>;
    }

    if (actor.type === 'account') {
      const actorEmail = actor.account?.attributes?.email;
      const isPeakonEmployee = actorEmail?.endsWith('@peakon.com');

      return (
        <Fragment>
          {isPeakonEmployee && (
            <img
              alt="Peakon Logo"
              src={PeakonLogo}
              className={styles.peakonLogo}
            />
          )}
          {actor.account.attributes.email ? (
            <Link
              to={`/employees?account.email=${actor.account.attributes.email}`}
            >
              {actor.account.attributes.email}
            </Link>
          ) : (
            <Link to={`/employees?accountId=${actor.account.id}`}>
              Account ID {actor.account.id}
            </Link>
          )}
        </Fragment>
      );
    }

    if (actor.type === 'appIntegration') {
      return <span className={styles.system}>Integration</span>;
    }
  }

  handleModalOpen = (syntheticEvent, audit) => {
    this.setState({
      isModalOpen: true,
      audit,
    });
  };

  renderModal() {
    const { isModalOpen, audit, showData } = this.state;

    if (audit === null) {
      return null;
    }

    const {
      attributes: {
        objectId,
        objectType,
        event,
        text,
        createdAt,
        address,
        agent: { os, osVersion, browser, version },
        location,
        data,
      },
      relationships: { company },
    } = audit;

    const action = `${objectType}.${event} (id: ${objectId})`;
    const actor = getActor(audit);

    const NotSet = () => <span className={styles.notSet}>Not set</span>;

    return (
      <Modal
        isOpen={isModalOpen}
        onClose={this.handleModalClose}
        title={action}
        style={{ width: 'auto', minWidth: 500 }}
      >
        <Modal.Header>
          <Modal.Title>
            <span className={styles.modalTitle}>
              <strong>
                {action}
                {address && `: Originated from ${address}`}
              </strong>
            </span>
          </Modal.Title>
          <Modal.CloseButton onClick={this.handleModalClose} />
        </Modal.Header>
        <Modal.Content>
          <table className={styles.modalTable}>
            <tbody>
              <tr>
                <th>Action</th>
                <td>
                  <div className={styles.system}>{action}</div>
                  <div>{text}</div>
                </td>
              </tr>
              <tr>
                <th>Actor</th>
                <td>{this.renderActor(actor)}</td>
              </tr>
              {company && (
                <tr>
                  <th>Company</th>
                  <td>{this.renderCompany(company, 'small')}</td>
                </tr>
              )}
              <tr>
                <th>OS</th>
                <td>
                  {os ? (
                    <span>
                      {this.renderOSIcon(os)} {os} ({osVersion})
                    </span>
                  ) : (
                    <NotSet />
                  )}
                </td>
              </tr>
              <tr>
                <th>Browser</th>
                <td>
                  {browser ? (
                    <span>
                      {this.renderBrowserIcon(browser)} {browser} ({version})
                    </span>
                  ) : (
                    <NotSet />
                  )}
                </td>
              </tr>
              <tr>
                <th>IP</th>
                <td>{address || <NotSet />}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>
                  {isEmpty(location) ? (
                    <NotSet />
                  ) : (
                    this.renderLocation(location)
                  )}
                </td>
              </tr>
              <tr>
                <th>Created</th>
                <td>
                  <div>{formatDate(createdAt)}</div>
                  <div>
                    <span className={styles.system}>{createdAt} (UTC)</span>
                  </div>
                </td>
              </tr>
              {data && (
                <tr>
                  <th>Data</th>
                  <td>
                    {showData ? (
                      <SyntaxHighlighter
                        language="javascript"
                        style={github}
                        wrapLongLines={true}
                      >
                        {JSON.stringify(data, null, 2)}
                      </SyntaxHighlighter>
                    ) : (
                      <a href="#" onClick={this.handleShowData}>
                        Show Data
                      </a>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Content>
      </Modal>
    );
  }

  handleShowData = () => {
    this.setState({ showData: true });
  };

  renderOSIcon(os) {
    if (os === 'Windows') {
      return <FaWindows />;
    }

    if (os === 'Mac OS X') {
      return <FaApple />;
    }

    if (os === 'Linux') {
      return <FaLinux />;
    }
  }

  renderBrowserIcon(browser) {
    if (browser === 'Chrome') {
      return <FaChrome />;
    }

    if (browser === 'Firefox') {
      return <FaFirefox />;
    }

    if (browser === 'Internet Explorer') {
      return <FaInternetExplorer />;
    }

    if (browser === 'Edge') {
      return <FaEdge />;
    }
  }

  renderLocation(location) {
    if (isEmpty(location)) {
      return null;
    }

    const { city, country, countryCode } = location;

    return (
      <span>
        {city && `${city},`} {country} {countryCode && `(${countryCode})`}
      </span>
    );
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false, showData: false });
  };

  search(page, continuation) {
    const {
      companyId,
      employee,
      auditActions: { getAuditsByCompanyId, getAuditsByEmployeeId },
    } = this.props;

    const { objectType, event, from, to, objectId } = this.state;

    if (objectId && !objectType) {
      return;
    }

    const opts = {
      objectType,
      event: event ? event.event : undefined,
      from,
      to,
      objectId,
    };

    if (companyId) {
      return getAuditsByCompanyId(companyId, page, continuation, opts);
    }

    if (employee) {
      return getAuditsByEmployeeId(employee.id, page, continuation, opts);
    }
  }

  handleContinuation = (isVisible) => {
    const {
      links: { next },
    } = this.props;

    if (isVisible && next) {
      const [, query] = next.split('?');
      const { page, continuation } = qs.parse(query, {
        ignoreQueryPrefix: true,
      });

      this.search(page, continuation);
    }
  };

  handleObjectTypeChange = (event) => {
    this.setState(
      {
        objectType: event ? event.value : undefined,
        event: undefined,
      },
      this.search,
    );
  };

  handleEventChange = (event) => {
    this.setState(
      {
        objectType: event ? event.objectType : undefined,
        event,
      },
      this.search,
    );
  };

  handleDateChange = (value, range) => {
    this.setState(
      {
        [range]: value,
      },
      this.search,
    );
  };

  handleObjectIdChange = (value) => {
    const parsedInt = parseInteger(value, undefined);
    if (parsedInt) {
      this.setState(
        {
          objectId: value,
          hasInputError: false,
        },
        this.search,
      );
    } else if (value === '') {
      this.setState(
        {
          objectId: undefined,
          hasInputError: false,
        },
        this.search,
      );
    } else if (parsedInt === undefined) {
      this.setState({ objectId: value, hasInputError: true });
    }
  };

  resetFilter = (syntheticEvent) => {
    syntheticEvent.preventDefault();

    this.setState(
      {
        objectId: undefined,
        objectType: undefined,
        event: undefined,
        from: undefined,
        to: undefined,
        hideSystemEvents: false,
      },
      this.search,
    );
  };

  handlehideSystemEventsChange = (value) => {
    this.setState({
      hideSystemEvents: value,
    });
  };
}

Audits.propTypes = {
  auditActions: PropTypes.object,
  companyId: PropTypes.string,
  employee: PropTypes.object,
  isLoading: PropTypes.bool,
  audits: PropTypes.array,
  links: PropTypes.object,
  objectTypes: PropTypes.object,
};

Audits.defaultProps = {
  audits: [],
  employee: null,
};

const mapStateToProps = (state) => {
  const { isLoading, audits, links, objectTypes } = state.audit;

  return {
    isLoading,
    audits,
    links,
    objectTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  auditActions: bindActionCreators(AuditActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audits);
