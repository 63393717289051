import React from 'react';

import PrivateRoute from 'components/PrivateRoute';
import PropTypes from 'prop-types';
import { Switch, useLocation } from 'react-router-dom';

import { AddPartnerConsultant } from './AddPartnerConsultant';
import { ListPartnerConsultants } from './ListPartnerConsultants';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

export const PartnerConsultants = (props) => {
  const { pathname } = useLocation();

  return (
    <RightsFilter rights={['backoffice:consultant:admin']}>
      <div className={styles.container}>
        <h2>Partner Consultants</h2>
        <Switch>
          <PrivateRoute
            path={pathname}
            exact
            component={ListPartnerConsultants}
            {...props}
          />
          <PrivateRoute
            path="./add"
            component={AddPartnerConsultant}
            {...props}
          />
        </Switch>
      </div>
    </RightsFilter>
  );
};

PartnerConsultants.propTypes = {
  company: PropTypes.object,
};
