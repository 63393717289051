import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { LinkButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Button } from '@peakon/components';

import {
  getConsultants,
  removeConsultant,
} from '../../../actions/CompanyActions';
import { showSuccessNotification } from '../../../actions/NotificationActions';
import api from '../../../client/peakonApi';
import asyncDispatch from '../../../utils/asyncDispatch';
import hasRequiredRight from '../../../utils/hasRequiredRight';

import styles from './styles.css';

export const ListPartnerConsultants = ({ company }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const consultants = useSelector((state) => state.company.consultants);
  const isLoadingConsultants = useSelector(
    (state) => state.company.isLoadingConsultants,
  );

  const { name, id } = company;

  useEffect(() => {
    const { rights } = session;

    if (hasRequiredRight(rights, ['backoffice:consultant:admin'])) {
      dispatch(getConsultants(id, true));
    }
  }, [session, id, dispatch]);

  const handleConsultantRemove = (consultant) => {
    const {
      id: consultantId,
      attributes: { email },
    } = consultant;

    const confirmed = confirm(
      `Are you sure you want to revoke partner consultant access for ${email}?`,
    );

    if (!confirmed) {
      return;
    }

    dispatch(removeConsultant(id, consultantId, true));
  };
  const sendPasswordReset = (consultant) => {
    const { email } = consultant.attributes;
    return asyncDispatch({
      dispatch,
      resource: 'EMPLOYEE_SEND_PASSWORD_RESET',
      action: api
        .post(`/auth/password/forgot`, null, {
          email,
          companyId: id,
        })
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'Password reset email will be sent.',
            }),
          );
          return response;
        }),
    });
  };

  return (
    <Fragment>
      {isLoadingConsultants ? (
        <div className={styles.spinner}>
          <Spinner size="48" />
        </div>
      ) : !consultants.length ? (
        <p>{name} does not have any partner consultants.</p>
      ) : (
        <table>
          <tbody>
            {consultants.map((consultant) => {
              const {
                id: consultantId,
                attributes: { email },
              } = consultant;

              return (
                <tr key={consultantId}>
                  <td>{email}</td>
                  <td className={styles.button}>
                    <Button
                      type="danger"
                      size="small"
                      onClick={() => handleConsultantRemove(consultant)}
                    >
                      Remove
                    </Button>
                  </td>
                  <td className={styles.button}>
                    <Button
                      type="prymary"
                      size="small"
                      onClick={() => sendPasswordReset(consultant)}
                    >
                      Reset password
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div>
        <LinkButton
          variant="primary"
          size="small"
          as={Link}
          to={`${pathname}/add`}
        >
          Add partner consultant
        </LinkButton>
      </div>
    </Fragment>
  );
};

ListPartnerConsultants.propTypes = {
  company: PropTypes.object,
};
