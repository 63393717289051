import env from 'client/env';

import jsonapiparser from '@peakon/jsonapiparser';

import {
  showErrorNotification,
  showSuccessNotification,
} from './NotificationActions';
import api from '../client/peakonApi';
import { PAGINATION_PER_PAGE_COUNT } from '../constants/PaginationConstants';
import asyncDispatch from '../utils/asyncDispatch';

const COMPANY_FIELDS = [
  'name',
  'size',
  'location',
  'domains',
  'companySubdomains',
  'accessAllowed',
  'accessPossible',
  'employeeLimit',
  'segmentLimitOverride',
  'salesforceId',
  'status',
  'employeeCount',
  'roundCount',
  'lastOpen',
  'nextOpen',
  'lastAnswerAt',
  'createdAt',
  'features',
  'addOns',
  'serviceStartedAt',
  'serviceEndedAt',
  'companyBlockAt',
  'companyDeleteAt',
  'allowAllDomains',
  'logo',
  'premiumStatus',
  'plan',
  'productPlans',
  'accessRegions',
  'benchmarkInfo',
  'benchmarkSector',
  'benchmarkIndustryGroup',
  'benchmarkIndustry',
  'sessionDuration',
  'workdayTenant',
  'workdayWebserviceHost',
  'settings',
  'deletedAt',
].join(',');

export const list =
  ({ page = 1, accountId } = {}) =>
  (dispatch) => {
    let params = {
      page,
      per_page: PAGINATION_PER_PAGE_COUNT,
      fields: {
        companies: COMPANY_FIELDS,
      },
      include: 'companySubdomains',
    };

    if (accountId) {
      params = {
        ...params,
        filter: {
          'consultants.accountId': `${accountId}$eq`,
        },
        include: 'companySubdomains',
      };
    }

    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_LIST',
      action: api.get('/companies', params),
    });
  };

export const search =
  ({ page = 1, q, filter } = {}) =>
  (dispatch) => {
    let params = {
      page,
      per_page: PAGINATION_PER_PAGE_COUNT,
      q,
      fields: {
        companies: COMPANY_FIELDS,
      },
      include: 'companySubdomains',
    };

    if (filter) {
      params = {
        ...params,
        filter,
      };
    }

    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_SEARCH',
      data: { page },
      action: api.get('/companies', params),
    });
  };

export const read = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_READ',
    data: { id },
    action: api.company.get(id, {
      fields: {
        companies: `${COMPANY_FIELDS},csm,customSenderDomains,companySubdomains`,
      },
      include: 'csm,customSenderDomains,companySubdomains',
    }),
  }).then((response) => {
    env.environment !== 'production' && dispatch(getMailDomains(id));
    return response;
  });
};

export const edit = (id) => async (dispatch) => {
  const company = await dispatch(read(id));
  return dispatch({
    type: 'COMPANY_EDIT_START',
    data: { company },
  });
};

export const update =
  (id, body = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_UPDATE',
      action: api
        .patch(
          `/companies/${id}`,
          {
            fields: {
              companies: `${COMPANY_FIELDS},csm`,
            },
            include: 'csm,companySubdomains',
          },
          body,
        )
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'The company has been updated',
            }),
          );

          return response;
        }),
    });
  };

export const updateCompanySettings =
  (id, body = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_SETTINGS_UPDATE',
      action: api
        .patch(
          `/companies/${id}/settings`,
          {
            fields: {
              companies: `${COMPANY_FIELDS},csm`,
            },
            include: 'csm,companySubdomains',
          },
          body,
        )
        .then((response) => {
          return response;
        }),
    });
  };

export const create =
  (body = {}) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_CREATE',
      action: api.post(`/accounts`, undefined, body).then((response) => {
        dispatch(
          showSuccessNotification({
            title: `The account ${body.email} has been created`,
          }),
        );

        return response;
      }),
    });
  };

export const getConsultants = (id, partnerConsultant) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_GET_CONSULTANTS',
    action: api.get(`/companies/${id}/consultants`, {
      isPartnerConsultant: partnerConsultant,
    }),
  });
};

export const resetSearch = () => (dispatch) => {
  dispatch({
    type: 'COMPANY_SEARCH_RESET',
  });
};

export const updatePriorities = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPDATE_PRIORITIES',
    action: api.post(`/priorities/recalculate/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'Priorities will be recalculated.',
        }),
      );

      return response;
    }),
  });
};

export const updateTopics = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPDATE_TOPICS',
    action: api.post(`/companies/${id}/update_topics`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'Topics will be updated for all rounds.',
        }),
      );

      return response;
    }),
  });
};

export const updateNotifications = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPDATE_NOTIFICATIONS',
    action: api
      .post(`/companies/${id}/update_notifications`)
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title:
              'Criticals and notifications will be updated for the last round.',
          }),
        );

        return response;
      }),
  });
};

export const upgradeEngagementQuestions = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPGRADE_ENGAGEMENT_QUESTIONS',
    action: api
      .post(`/questions/upgrade/${id}`, { group: 'engagement' })
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Successfully upgraded questions.',
          }),
        );

        return response;
      }),
  });
};

export const upgradeDiversityInclusionQuestions = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPGRADE_DIVERSITY_INCLUSION_QUESTIONS',
    action: api
      .post(`/questions/upgrade/${id}`, { group: 'diversity_inclusion' })
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Successfully upgraded questions.',
          }),
        );

        return response;
      }),
  });
};

export const upgradeHealthWellbeingQuestions = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPGRADE_HEALTH_WELLBEING_QUESTIONS',
    action: api
      .post(`/questions/upgrade/${id}`, { group: 'health_wellbeing' })
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Successfully upgraded questions.',
          }),
        );

        return response;
      }),
  });
};

export const upgradeTextQuestions = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPGRADE_TEXT_QUESTIONS',
    action: api
      .post(`/questions/upgrade/${id}`, { group: 'text' })
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Successfully upgraded questions.',
          }),
        );

        return response;
      }),
  });
};

export const upgradeCovid19Questions = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPGRADE_COVID19_QUESTIONS',
    action: api
      .post(`/questions/upgrade/${id}`, { group: 'covid19' })
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Successfully upgraded questions.',
          }),
        );

        return response;
      }),
  });
};

export const upgradeSensitive = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UPGRADE_SENSITIVE',
    action: api.post(`/sensitive/upgrade/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'Sensitive concepts and keywords will be upgraded.',
        }),
      );

      return response;
    }),
  });
};

export const blockAccess = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_BLOCK_ACCESS',
    action: api.post(`/companies/${id}/block`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The company has been blocked.',
        }),
      );

      dispatch(read(id));

      return response;
    }),
  });
};

export const unblockAccess = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_UNBLOCK_ACCESS',
    action: api.post(`/companies/${id}/unblock`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The company has been unblocked.',
        }),
      );

      dispatch(read(id));

      return response;
    }),
  });
};

export const deleteCompany = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_DELETE',
    action: api.delete(`/companies/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The company will be deleted.',
        }),
      );

      return response;
    }),
  });
};

export const addDomain = (id, domain) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_DOMAIN_ADD',
    action: api
      .post(
        `/companies/${id}/domains`,
        {},
        {
          domain,
        },
      )
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'The domain has been added.',
          }),
        );

        dispatch(read(id));

        return response;
      }),
  });
};

export const removeDomain = (id, domain) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_DOMAIN_REMOVE',
    action: api
      .delete(`/companies/${id}/domains/${encodeURIComponent(domain)}`)
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'The domain has been removed.',
          }),
        );

        dispatch(read(id));

        return response;
      }),
  });
};

export const getMailDomains = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_MAIL_DOMAINS_READ',
    action: api.get(`/companies/${id}/maildomains`),
  });
};

export const addEmailDomain = (id, emailDomain) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_EMAIL_DOMAIN_ADD',
    action: api
      .post(
        `/companies/${id}/maildomains`,
        {},
        {
          mailDomain: emailDomain,
        },
      )
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'The email domain has been added.',
          }),
        );

        dispatch(read(id));

        return response;
      }),
  });
};

export const removeEmailDomain = (id, emailDomain) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_EMAIL_DOMAIN_REMOVE',
    action: api
      .delete(`/companies/${id}/maildomains/${encodeURIComponent(emailDomain)}`)
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'The email domain has been removed.',
          }),
        );

        dispatch(read(id));

        return response;
      }),
  });
};

export const updateAddOns = (id, addOns) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_ADDONS_UPDATE',
    action: api
      .patch(
        `/companies/${id}/add_ons`,
        {},
        {
          addOns,
        },
      )
      .then((response) => {
        dispatch(
          showSuccessNotification({
            title: 'Add-ons & sub-contractors have been updated.',
          }),
        );

        dispatch(read(id));

        return response;
      }),
  });
};

export const addPartnerConsultant =
  ({ companyId, ...payload }) =>
  async (dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'COMPANY_CONSULTANT_ADD',
      action: api
        .post(`/company/${companyId}/partner`, null, payload)
        .then(() => dispatch(getConsultants(companyId, true))),
    });

export const addConsultant = (id, email, expiresAt) => async (dispatch) => {
  let response;

  try {
    response = await api.get(`/employees/contexts/backoffice`, {
      filter: {
        'account.email': email,
      },
    });
  } catch (err) {
    return dispatch(
      showErrorNotification({
        title: err.message,
      }),
    );
  }
  if (response.data.length === 0) {
    dispatch(
      showErrorNotification({
        title: `No account found with e-mail ${email}`,
      }),
    );
  } else {
    const [employee] = response.data;

    const {
      relationships: {
        account: {
          data: { id: accountId },
        },
      },
    } = employee;

    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_CONSULTANT_ADD',
      action: api
        .post(`/companies/${id}/consultants/${accountId}`, null, { expiresAt })
        .then((addConsultantResponse) => {
          dispatch(
            showSuccessNotification({
              title: `${email} has been added as consultant.`,
            }),
          );

          dispatch(getConsultants(id, false));

          return addConsultantResponse;
        }),
    });
  }
};

export const removeConsultant =
  (id, consultantId, partnerConsultant) => (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANY_CONSULTANT_REMOVE',
      action: api
        .delete(`/companies/${id}/consultants/${consultantId}`)
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'The consultant access has been revoked.',
            }),
          );

          dispatch(getConsultants(id, partnerConsultant));

          return response;
        }),
    });
  };

export const getFormerCompanies =
  (page = 1) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANIES_LIST_FORMER',
      action: api.get('/companies', {
        order: 'asc',
        page,
        per_page: 10,
        sort: 'serviceEndedAt',
        fields: {
          companies: ['name', 'status', 'size', 'serviceEndedAt'].join(','),
        },
        filter: {
          anonymizedAt: 'null',
          serviceEndedAt: 'null$ne',
        },
      }),
    });
  };

export const getPendingCompanies =
  (page = 1) =>
  (dispatch) => {
    return asyncDispatch({
      dispatch,
      resource: 'COMPANIES_LIST_PENDING',
      action: api
        .get('/companies/pending_deletion', {
          page,
          per_page: 10,
          fields: {
            accounts: ['email'].join(','),
            companies: [
              'name',
              'status',
              'deletionRequestedAt',
              'lastAnswerAt',
              'blockedBy',
            ].join(','),
          },
          include: ['blockedBy'].join(','),
        })
        .then(jsonapiparser),
    });
  };

export const getAdmins = (companyId) => (dispatch) => {
  const params = {
    include: ['account'].join(','),
    fields: {
      employees: ['+', 'account', 'reportCount'].join(','),
      accounts: ['lastSeenAt', 'email'].join(','),
    },
  };

  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_ADMINS_READ',
    action: api
      .get(`/groups/admin/members/company/${companyId}`, params)
      .then((response) => jsonapiparser(response)),
  });
};

export const reset = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'COMPANY_RESET',
    action: api.post(`/companies/${id}/reset`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The company data will be reset.',
        }),
      );

      return response;
    }),
  });
};

export const revealCommentIdentity = (commentId, body) => async (dispatch) => {
  try {
    return await api.post(`/comments/${commentId}/identity`, null, body);
  } catch (err) {
    return dispatch(
      showErrorNotification({
        title: err.message,
      }),
    );
  }
};
