import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { showSuccessNotification } from 'actions/NotificationActions';
import { InputField } from 'components/RHF-Zod-Form/Input';
import { Select } from 'components/RHF-Zod-Form/Select';
import PropTypes from 'prop-types';
import { useForm, FormProvider, Form } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '@peakon/bedrock/react/button';

import { addPartnerConsultant } from '../../../actions/CompanyActions';

import styles from './styles.css';

const DATE_FORMAT = 'YYYY-MM-DD';

const REGIONS = [
  'europe',
  'asia',
  'australia',
  'africa',
  'north_america',
  'south_america',
];

const schema = z.object({
  email: z.string().email(),
  expiresAt: z
    .string()
    .regex(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, {
      message: `Please enter a valid date in the format ${DATE_FORMAT}`,
    })
    .optional(),
  region: z.enum(REGIONS).optional(),
});

export const AddPartnerConsultant = ({ company: { id: companyId } }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: zodResolver(schema),
  });

  const goToListPage = () => {
    history.push(`/companies/${companyId}/partner_consultants`);
  };

  const onSubmit = async (formData) => {
    try {
      await dispatch(addPartnerConsultant({ companyId, ...formData }));
      dispatch(
        showSuccessNotification({
          title: `${formData.email} has been added as a partner consultant.`,
        }),
      );
      goToListPage();
    } catch (error) {
      methods.setError('email', { message: error.message });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={styles.formContent}
      >
        <InputField name="email" label="Email" type="text" required />
        <InputField
          name="expiresAt"
          label="Expiration Date"
          description={`${DATE_FORMAT}; Leave blank for unlimited`}
          type="text"
        />
        <Select name="region" label="Region">
          <Select.Option value="" disabled>
            Select a region
          </Select.Option>
          {REGIONS.map((region) => (
            <Select.Option key={region} value={region}>
              {region}
            </Select.Option>
          ))}
        </Select>
        <div className={styles.formFooter}>
          <Button variant="primary" size="small" type="submit">
            Add
          </Button>
          <Button variant="secondary" size="small" onClick={goToListPage}>
            Cancel
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

AddPartnerConsultant.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
  }),
};
