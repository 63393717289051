import React from 'react';

import { withRouter, NavLink } from 'react-router-dom';

import env from '../../../client/env';
import RightsFilter from '../../../components/RightsFilter';

import styles from './styles.css';

// eslint-disable-next-line react/prop-types
function SideNavComponent({ isAuthenticated }) {
  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav className={styles.sideNav}>
      <RightsFilter rights={['backoffice:access:admin']}>
        <NavLink className={styles.sideNavLink} exact to="/" title="Overview">
          Overview
        </NavLink>
        <NavLink
          className={styles.sideNavLink}
          to="/backoffice-access"
          title="Backoffice Access"
        >
          Backoffice Access
        </NavLink>
      </RightsFilter>
      <RightsFilter rights={['backoffice:benchmarks:admin']}>
        <NavLink
          className={styles.sideNavLink}
          to="/benchmarks"
          title="Benchmarks"
        >
          Benchmarks
        </NavLink>
      </RightsFilter>
      {env.environment !== 'production' && (
        <RightsFilter rights={['testCompany:admin']}>
          <NavLink
            className={styles.sideNavLink}
            to="/test-companies"
            title="Test Companies"
          >
            Test Companies
          </NavLink>
        </RightsFilter>
      )}
      <RightsFilter rights={['backoffice:company:read']}>
        <NavLink
          className={styles.sideNavLink}
          to="/companies"
          title="Companies"
        >
          Companies
        </NavLink>
      </RightsFilter>
      <RightsFilter rights={['backoffice:employee:read']}>
        <NavLink
          className={styles.sideNavLink}
          to="/employees"
          title="Employees"
        >
          Employees
        </NavLink>
      </RightsFilter>
      <RightsFilter rights={['backoffice:response:read']}>
        <NavLink
          className={styles.sideNavLink}
          to="/responses"
          title="Responses"
        >
          Responses
        </NavLink>
      </RightsFilter>
      <RightsFilter rights={['backoffice:company:delete']}>
        <NavLink
          className={styles.sideNavLink}
          to="/former-customers"
          title="Former Customers"
        >
          Former Customers
        </NavLink>
      </RightsFilter>
      <RightsFilter rights={['backoffice:company:create']}>
        <NavLink
          className={styles.sideNavLink}
          to="/signup"
          title="Company Signup"
        >
          Signup
        </NavLink>
      </RightsFilter>
    </nav>
  );
}

export const SideNav = withRouter(SideNavComponent);
