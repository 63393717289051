import React, { Fragment, useEffect } from 'react';

import NavLinkTab from 'components/NavLinkTab';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { LinkButton } from '@peakon/bedrock/react/button';
import { TabNavigation } from '@peakon/bedrock/react/navigation';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Avatar, View } from '@peakon/components';

import Actions from './Actions';
import Admins from './Admins';
import BenchmarkVersions from './BenchmarkVersions';
import Comments from './Comments';
import Consultants from './Consultants';
import Domains from './Domains';
import Integrations from './Integrations';
import Overview from './Overview';
import { PartnerConsultants } from './PartnerConsultants';
import Plans from './Plans';
import Settings from './Settings';
import CompanySubdomains from './Subdomains';
import * as CompanyActions from '../../actions/CompanyActions';
import { getAbbreviation } from '../../utils/abbreviation';
import { getPlanForBadge } from '../../utils/company';
import { getDashboardURL } from '../../utils/getDashboardURL';
import getImgixUrl from '../../utils/getImgixUrl';
import Audits from '../Audits';
import BreadCrumbs from '../BreadCrumbs';
import PlanBadge from '../PlanBadge';
import PrivateRoute from '../PrivateRoute';
import ResourceHeader from '../ResourceHeader';
import RightsFilter from '../RightsFilter';

import styles from './styles.css';

const Company = ({ match }) => {
  const companyId = match?.params?.id;
  const dispatch = useDispatch();
  const { company, isLoading } = useSelector((state) => {
    return {
      company: state.companies.items.find((c) => c.id === companyId),
      isLoading: state.companies.isLoading,
    };
  });

  useEffect(() => {
    dispatch(CompanyActions.edit(companyId));
  }, [companyId, dispatch]);

  if (isLoading && !company) {
    return (
      <div className={styles.spinner}>
        <Spinner size="48" />
      </div>
    );
  }

  if (!company) {
    return null;
  }

  const primarySubdomain = company.companySubdomains?.find(
    (subdomain) => subdomain.attributes.primary === true,
  )?.attributes?.subdomain;

  const {
    id,
    name,
    status,
    logo,
    createdAt,
    accessPossible,
    deletedAt,
    companyBlockAt,
    companyDeleteAt,
  } = company;

  const isBlocked = status === 'blocked';
  const isDeleted = Boolean(deletedAt);

  return (
    <View className={styles.container}>
      <ResourceHeader blocked={isBlocked}>
        <BreadCrumbs>
          <BreadCrumbs.Link to="/companies">Companies</BreadCrumbs.Link>
          <BreadCrumbs.Link to="#" isLast>
            {name}
          </BreadCrumbs.Link>
        </BreadCrumbs>
        <View className={styles.headerContent}>
          <View className={styles.companyHeader}>
            <View className={styles.companyLogo}>
              <Avatar
                src={getImgixUrl({
                  src: logo,
                  width: 128,
                  height: 128,
                  fit: 'crop',
                })}
                abbreviation={getAbbreviation(name)}
                size="large"
                type="logo"
              />
            </View>
            <View>
              <ResourceHeader.Heading>
                <span>
                  {name} - ID: {id}
                </span>
                <div className={styles.companyPlan}>
                  <PlanBadge plan={getPlanForBadge(company)} />
                </div>
              </ResourceHeader.Heading>
              <View>
                Created {moment(createdAt).utc().locale('en-GB').format('ll')}
              </View>
              {companyBlockAt && !isBlocked && (
                <View className={styles.dueBlockDelete}>
                  Due to be blocked on {moment.utc(companyBlockAt).format('ll')}
                </View>
              )}
              {companyDeleteAt && isBlocked && !isDeleted && (
                <View className={styles.dueBlockDelete}>
                  Due to be deleted on{' '}
                  {moment.utc(companyDeleteAt).format('ll')}
                </View>
              )}
            </View>
            <View className={styles.companyActions}>
              {accessPossible && !isDeleted && (
                <LinkButton
                  size="small"
                  variant="primary"
                  href={getDashboardURL({
                    pathname: '/login',
                    searchParams: { company_id: id },
                    subdomain: primarySubdomain,
                  })}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Log in as {name}
                </LinkButton>
              )}
            </View>
          </View>
        </View>
        <View className={styles.footer}>
          <TabNavigation aria-label="Company Tab Navigation">
            <NavLinkTab exact to={`/companies/${company.id}`}>
              Overview
            </NavLinkTab>
            {!isDeleted && (
              <Fragment>
                <NavLinkTab exact to={`/companies/${company.id}/settings`}>
                  Settings
                </NavLinkTab>
                <NavLinkTab exact to={`/companies/${company.id}/actions`}>
                  Actions
                </NavLinkTab>
                <NavLinkTab exact to={`/companies/${company.id}/domains`}>
                  Domains
                </NavLinkTab>
                <RightsFilter rights={['backoffice:company:admin']}>
                  <NavLinkTab exact to={`/companies/${company.id}/subdomains`}>
                    Subdomains
                  </NavLinkTab>
                </RightsFilter>
                <NavLinkTab
                  exact
                  to={`/companies/${company.id}/benchmark_versions`}
                >
                  Benchmark Versions
                </NavLinkTab>
                <NavLinkTab exact to={`/companies/${company.id}/plans`}>
                  Plans
                </NavLinkTab>
                <RightsFilter rights={['backoffice:consultant:admin']}>
                  <NavLinkTab exact to={`/companies/${company.id}/consultants`}>
                    Consultants
                  </NavLinkTab>
                </RightsFilter>
                <RightsFilter rights={['backoffice:consultant:admin']}>
                  <NavLinkTab
                    to={`/companies/${company.id}/partner_consultants`}
                  >
                    Partner Consultants
                  </NavLinkTab>
                </RightsFilter>
                <NavLinkTab exact to={`/companies/${company.id}/admins`}>
                  Admins
                </NavLinkTab>
                <NavLinkTab exact to={`/companies/${company.id}/integrations`}>
                  Integrations
                </NavLinkTab>
                <NavLinkTab exact to={`/companies/${company.id}/comments`}>
                  Comments
                </NavLinkTab>
              </Fragment>
            )}
            <NavLinkTab exact to={`/companies/${company.id}/audits`}>
              Audits
            </NavLinkTab>
          </TabNavigation>
        </View>
      </ResourceHeader>
      <div className={styles.bodyContent}>
        <Switch>
          <PrivateRoute
            path={match.path}
            exact
            component={Overview}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/actions`}
            component={Actions}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/domains`}
            component={Domains}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/subdomains`}
            component={CompanySubdomains}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/benchmark_versions`}
            component={BenchmarkVersions}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/plans`}
            component={Plans}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/consultants`}
            component={Consultants}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/partner_consultants`}
            component={PartnerConsultants}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/admins`}
            component={Admins}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/integrations`}
            component={Integrations}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/audits`}
            component={Audits}
            companyId={company.id}
          />
          <PrivateRoute
            path={`${match.path}/settings`}
            component={Settings}
            company={company}
          />
          <PrivateRoute
            path={`${match.path}/comments`}
            component={Comments}
            company={company}
          />
        </Switch>
      </div>
    </View>
  );
};

Company.propTypes = {
  match: PropTypes.object,
};

export default Company;
