import {
  showErrorNotification,
  showSuccessNotification,
} from './NotificationActions';
import api from '../client/peakonApi';
import asyncDispatch from '../utils/asyncDispatch';

export const getAccounts = () => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_READ',
    action: api.get('/access', {
      order: 'asc',
      fields: {
        accounts: ['companyId', 'email', 'role', 'region'].join(','),
      },
    }),
  });
};

export const udpate = (id, body) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_ROLE_UPDATE',
    action: api.put(`/access/${id}`, {}, body).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The Backoffice account has been updated.',
        }),
      );

      dispatch(getAccounts());

      return response;
    }),
  });
};

async function checkEmployeeAccount(email, companyId, dispatch) {
  const response = await api.get(`/employees/contexts/backoffice`, {
    filter: {
      'account.email': email,
      // only add companyId filter for partner consultants now
      ...(companyId && { companyId }),
    },
  });

  if (response.data.length === 0) {
    return dispatch(
      showErrorNotification({
        title: `No account found with e-mail ${email}`,
      }),
    );
  }
  // only have this check for partner consultants now, who need to have companyId provided in the payload
  if (companyId && response.data.length > 1) {
    return dispatch(
      showErrorNotification({
        title: `More than 1 account found with e-mail ${email}`,
      }),
    );
  }
  return response.data[0].relationships.account.data.id;
}

export const addAccount = (email, body) => async (dispatch) => {
  const { companyId, role, region } = body;
  const accountId = await checkEmployeeAccount(email, companyId, dispatch);

  if (!accountId) {
    return;
  }
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_ADD',
    action: api
      .put(`/access/${accountId}`, {}, body)
      .then((addAccountResponse) => {
        dispatch(
          showSuccessNotification({
            title: `${email} has been created with role ${role} for ${region}.`,
          }),
        );

        dispatch(getAccounts());

        return addAccountResponse;
      }),
  });
};

export const removeAccount = (id) => (dispatch) => {
  return asyncDispatch({
    dispatch,
    resource: 'ACCESS_ACCOUNTS_REMOVE',
    action: api.delete(`/access/${id}`).then((response) => {
      dispatch(
        showSuccessNotification({
          title: 'The account has been deleted.',
        }),
      );

      dispatch(getAccounts());

      return response;
    }),
  });
};
